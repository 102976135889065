// ImageModal.jsx
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import ImageSlider from './ImageSlider';
import SliderNavigation from './SliderNavigation';
import SliderDots from './SliderDots';
import useScrollLock from '../hooks/useScrollLock';
import { useModal } from 'context/Modal'; // Import the useModal hook

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.93);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 90%;
  max-width: 800px;
  color: #fff;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ImageCount = styled.span`
  font-size: 18px;
`;

const CloseIcon = styled(FaTimes)`
  font-size: 24px;
  cursor: pointer;
`;

const ModalImageContainer = styled.div`
  position: relative;
`;

const Description = styled.p`
  margin-top: 10px;
  font-size: 16px;
`;

const ImageModal = ({ images }) => {
  useScrollLock(true);
  const { currentIndex, setCurrentIndex, closeModal } = useModal(); // Destructure from context
  const totalImages = images.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < totalImages - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const showPrev = currentIndex > 0;
  const showNext = currentIndex < totalImages - 1;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ImageCount>{`${currentIndex + 1}/${totalImages}`}</ImageCount>
          <CloseIcon onClick={closeModal} />
        </ModalHeader>
        <ModalImageContainer>
          <ImageSlider
            images={images}
            currentIndex={currentIndex}
            onPrev={handlePrev}
            onNext={handleNext}
          />
          <SliderNavigation
            onPrev={handlePrev}
            onNext={handleNext}
            showPrev={showPrev}
            showNext={showNext}
          />
        </ModalImageContainer>
        <SliderDots
          totalImages={totalImages}
          currentIndex={currentIndex}
          onDotClick={handleDotClick}
        />
        <Description>{images[currentIndex].description}</Description>
      </ModalContent>
    </ModalOverlay>
  );
};

const ImageShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

ImageModal.propTypes = {
  images: PropTypes.arrayOf(ImageShape).isRequired,
};

export default ImageModal;
