import React from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaArrowRight } from 'react-icons/fa';
import PropTypes from 'prop-types';

const CardBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #fff;
`;

const Previous = styled.button`
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border-right: 1px solid #fff;
  background: none;
  color: #fff;
  cursor: pointer;

  &:disabled {
    color: grey;
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    padding: 25px 35px;
    font-size: 2rem;
  }
`;

const ArrowLeft = styled(FaChevronLeft)`
  font-size: 2.5rem;
`;

const Next = styled.button`
  padding: 15px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-size: 1.8rem;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  gap: 10px;

  &:disabled {
    color: grey;
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    padding: 25px 35px;
    font-size: 2rem;
  }
`;

const ArrowRight = styled(FaArrowRight)`
  font-size: 2.5rem;
`;

const CardBottom = ({
  handleNext,
  handlePrev,
  isNextDisabled,
  isPrevDisabled,
}) => (
  <CardBottomWrapper>
    <Previous onClick={handlePrev} disabled={isPrevDisabled}>
      <ArrowLeft />
    </Previous>
    <Next onClick={handleNext} disabled={isNextDisabled}>
      UPCOMING EVENT
      <ArrowRight />
    </Next>
  </CardBottomWrapper>
);

CardBottom.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  isPrevDisabled: PropTypes.bool.isRequired,
};

export default CardBottom;
