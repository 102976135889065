// ImageSlider.jsx
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { useModal } from '../context/Modal'; // Import the useModal hook

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  background-color: #000;
`;

const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(${(props) => -props.currentIndex * 100}%);
  height: 100%;
`;

const Slide = styled.div`
  flex: 0 0 100%;
  height: 100%;
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer; // Indicate that the image is clickable
`;

/**
 * A component that renders a slider of images, with swipe gestures for navigation.
 *
 * @param {Object[]} images - An array of objects containing image data, with `url` and `description` properties
 * @param {number} currentIndex - The index of the currently displayed image
 * @param {function} onPrev - A callback invoked when the user swipes to the left
 * @param {function} onNext - A callback invoked when the user swipes to the right
 * @returns {ReactElement} A `div` element with a `SliderWrapper` and `Slide` elements as children
 */
const ImageSlider = ({ images, currentIndex, onPrev, onNext }) => {
  const totalImages = images.length;
  const { openModal } = useModal(); // Use the modal context

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < totalImages - 1) {
        onNext();
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        onPrev();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  const handleImageClick = (index) => {
    openModal(index); // Open the modal with the clicked image index
  };

  return (
    <SliderContainer {...handlers}>
      <SliderWrapper currentIndex={currentIndex}>
        {images.map((image, index) => (
          <Slide key={image.url}>
            <SlideImage
              src={image.url}
              alt={image.description}
              onClick={() => handleImageClick(index)}
            />
          </Slide>
        ))}
      </SliderWrapper>
    </SliderContainer>
  );
};

const ImageShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(ImageShape).isRequired,
  currentIndex: PropTypes.number.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default ImageSlider;
