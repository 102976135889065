// SliderNavigation.js
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const NavigationContainer = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0 10px;
  pointer-events: none;
  z-index: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavButton = styled.button`
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  transition: background-color 0.2s ease;
  line-height: 1;

  &:hover {
    background-color: rgba(204, 204, 204, 0.5);
  }

  &:focus {
    outline: none;
  }

  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

const ArrowIcon = styled.span`
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin-top: -4px;
  ${(props) => (props.direction === 'prev' ? 'margin-right: 4px;' : '')}
  ${(props) => (props.direction === 'next' ? 'margin-left: 4px;' : '')}
`;

const SliderNavigation = ({ onPrev, onNext, showPrev, showNext }) => (
  <NavigationContainer>
    <NavButton onClick={onPrev} hidden={!showPrev}>
      <ArrowIcon direction='prev'>&lsaquo;</ArrowIcon>
    </NavButton>
    <NavButton onClick={onNext} hidden={!showNext}>
      <ArrowIcon direction='next'>&rsaquo;</ArrowIcon>
    </NavButton>
  </NavigationContainer>
);

SliderNavigation.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
};

SliderNavigation.defaultProps = {
  showPrev: true,
  showNext: true,
};

export default SliderNavigation;
