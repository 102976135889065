import styled from '@emotion/styled';
import { FaPhone, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';
import { siteInfo, socialMediaLinks, images } from '../config';
import ContactForm from './ContactForm';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  position: relative;
  padding-top: 200px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${images.contactBackground});
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: -2;
  }
  @media (max-width: 769px) {
    padding-top: 100px;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const ContactDetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px;
`;

const ContactPhoneNumber = styled.div`
  font-family: 'Teko', sans-serif;
  font-size: 2.5rem;
  color: var(--accent-color-2);
  line-height: 30px;
  letter-spacing: 0.6px;
`;

const ContactDetailsIcon = styled.div`
  font-size: 3rem;
  color: var(--primary-color);
`;

const ContactDetailsText = styled.div`
  font-family: 'Teko', sans-serif;
  font-size: 1.5rem;
  color: var(--neutral-dark);
  line-height: 30px;
  letter-spacing: 0.5px;
`;

const ContactDetailsEmail = styled.div`
  font-family: 'Teko', sans-serif;
  font-size: 2rem;
  line-height: 30px;
  letter-spacing: 0.6px;
  a {
    color: var(--accent-color-2);
    &:hover {
      color: var(--complementary-color);
    }
  }
`;

const ContactTitle = styled.h2`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 4rem;
  color: var(--neutral-dark);
  line-height: 30px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: absolute;
  top: 50px;
  @media (min-width: 769px) {
    font-size: 5rem;
    top: 140px;
  }
`;
const SectionShadowTitle = styled.span`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 5rem;
  text-align: center;
  color: #eee;
  position: absolute;
  top: 20px;
  z-index: -1;
  @media (min-width: 769px) {
    font-size: 9rem;
    top: 75px;
  }
`;

const SocialSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  a {
    padding: 0 5px;
    font-size: 3.5rem;
    color: var(--neutral-dark);
    &:hover {
      color: var(--complementary-color);
    }
  }
`;

const Contact = () => (
  <ContactContainer id='contact'>
    <SectionShadowTitle>Contact Us</SectionShadowTitle>
    <ContactTitle>Contact Us</ContactTitle>
    <ContactDetails>
      <ContactDetailsItem>
        <ContactDetailsIcon>
          <FaPhone alt='Phone' />
        </ContactDetailsIcon>
        <ContactDetailsText />
        <ContactPhoneNumber>{siteInfo.phone}</ContactPhoneNumber>
      </ContactDetailsItem>
      <ContactDetailsItem>
        <ContactDetailsIcon>
          <FaEnvelope alt='Email' />
        </ContactDetailsIcon>
        <ContactDetailsEmail>
          <a href={`mailto:${siteInfo.email2}`}>{siteInfo.email2}</a>
        </ContactDetailsEmail>
      </ContactDetailsItem>
    </ContactDetails>
    <SocialSection>
      <a href={socialMediaLinks.facebook}>
        <FaFacebook alt='Facebook' />
      </a>
      <a href={socialMediaLinks.instagram}>
        <FaInstagram alt='Instagram' />
      </a>
    </SocialSection>
    <ContactForm />
  </ContactContainer>
);

export default Contact;
