import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { FaBars } from 'react-icons/fa';
import Navbar from './Navbar';
import NavMenu from './NavMenu';

import { sections, images } from '../config';

const AltHeader = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100px;
  background-color: rgba(51, 51, 51, 0.98);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    svg {
      background-color: var(--complementary-color);
      padding: 10px;
      margin-right: 20px;
      border-radius: 20%;
    }
    box-shadow: none;
    background-color: transparent;
    justify-content: flex-end;
  }
`;

const BannerWrapper = styled.section`
  height: 800px;
  background-image: url(${images.bannerBackground}),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 100%);
  background-blend-mode: soft-light;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: -100px;
    width: 100%;
    height: 50%;
    left: 0;
    right: 0;
    background-image: url(${images.bannerLowerTransition});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @media (max-width: 425px) {
    height: 100vh;
  }
`;

const BannerMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

const Message = styled.h1`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 10rem;
  text-align: center;
  color: #fff;
  max-width: 1000px;
  padding: 0 10px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    max-width: 700px;
  }
  @media (max-width: 768px) {
    margin-top: 10vh;
    font-size: 6rem;
    max-width: 600px;
  }
  @media (max-width: 425px) {
    font-size: 5rem;
    max-width: 400px;
  }
`;

const Tagline = styled.p`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 3rem;
  text-align: center;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 425px) {
    font-size: 1.5rem;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    max-width: 175px;
    max-height: 100%;
    object-fit: contain;
  }
  @media (max-width: 1024px) {
    img {
      max-width: 100px;
      max-height: 90%;
      object-fit: contain;
    }
  }
  @media (max-width: 768px) {
    justify-self: start;
  }
`;
const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 20px 30px 0 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Navigation = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const HamburgerIcon = styled(FaBars)`
  color: #ffffff;
  font-size: 3rem;
  cursor: pointer;
  display: none;
  padding: 15px;

  &:hover {
    color: #658354;
  }

  @media (max-width: 768px) {
    display: block;
    justify-self: end;
  }
`;

const Banner = () => {
  const [showAltHeader, setShowAltHeader] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowAltHeader(!entry.isIntersecting);
      },
      {
        threshold: 0.9,
        rootMargin: '0px',
      }
    );

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        observer.unobserve(headerRef.current);
      }
    };
  }, [headerRef]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      {showAltHeader && (
        <AltHeader>
          <Navigation>
            <Navbar />
          </Navigation>
          <HamburgerIcon onClick={toggleMenu} />
        </AltHeader>
      )}
      <NavMenu
        isMenuOpen={isMenuOpen}
        closeMenu={closeMenu}
        sections={sections}
      />
      <BannerWrapper id='top'>
        <Header>
          <Logo>
            <img src={images.bannerLogo} alt='Logo' />
          </Logo>
          <Navigation>
            <Navbar />
          </Navigation>
          <HamburgerIcon onClick={toggleMenu} />
          {/* <ContactButton>Contact Us</ContactButton> */}
        </Header>
        <span ref={headerRef} />
        <BannerMessage>
          <Message>Food That Fuels Your Passion</Message>
          <Tagline>Quality ingredients and delicious flavors</Tagline>
        </BannerMessage>
      </BannerWrapper>
    </>
  );
};

export default Banner;
