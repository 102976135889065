import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { images } from '../../../config';
import CardTop from './CardTop';
import CardBottom from './CardBottom';

const EventCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 600px;
  height: 450px;
  max-height: 80vh;
  padding: 10px;
  background-image: url(${images.boards});
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(60, 84, 45, 0.85);
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  @media (min-width: 768px) {
    height: 550px;

    min-width: 500px;
    max-width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
`;

const EventCardBorder = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EventCard = ({
  event,
  handleNext,
  handlePrev,
  isNextDisabled,
  isPrevDisabled,
  totalEvents,
}) => (
  <EventCardWrapper>
    <EventCardBorder>
      <CardTop event={event} />
      {totalEvents > 1 && (
        <CardBottom
          handleNext={handleNext}
          handlePrev={handlePrev}
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
        />
      )}
    </EventCardBorder>
  </EventCardWrapper>
);

EventCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    date: PropTypes.string,
    image1: PropTypes.string,
    image2: PropTypes.string,
  }).isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  isPrevDisabled: PropTypes.bool.isRequired,
  totalEvents: PropTypes.number.isRequired,
};

export default EventCard;
