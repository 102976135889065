import styled from 'styled-components';
import { FaPhone, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';
import { siteInfo, socialMediaLinks, images } from '../config';

const Bar = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 50px;
  padding: 0 40px;

  overflow: visible;
  background-color: #ffffff;
å  
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    right: 15%;
    bottom: -75px;
    width: 750px;
    height: 120px;
    background-image: url(${images.ctaLowerTransition});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Section = styled.div`
  font-family: 'PoppinsRegular', sans-serif;
  display: flex;
  flex-direction: row;
  color: #1f1f1f;
  align-items: center;
  height: 100%;
  a {
    letter-spacing: 0.01em;
    font-size: 1.8rem;
    text-decoration: none;
    color: inherit;
    &:hover {
      color: #658354;
    }
  }
`;

const SocialSection = styled.div`
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #dbdbdb;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  color: #909090;
  a {
    padding: 0 5px;
    font-size: 2.8rem;
    &:hover {
      color: #658354;
    }
  }
`;

const Icon = styled.span`
  color: #658354;
  margin-right: 10px;
  font-size: 2.8rem;
  display: flex;
  align-items: center;
`;

function ContactBar() {
  return (
    <Bar>
      <Section>
        <Icon>
          <FaEnvelope />
        </Icon>
        <a href={`mailto:${siteInfo.email1}`}>{siteInfo.email1}</a>
        <SocialSection>
          <a href={socialMediaLinks.instagram} aria-label='Instagram'>
            <FaInstagram />
          </a>
          <a href={socialMediaLinks.facebook} aria-label='Instagram'>
            <FaFacebook />
          </a>
        </SocialSection>
      </Section>
      <Section>
        <Icon>
          <FaPhone />
        </Icon>
        <a href={`tel:${siteInfo.phone}`}>{siteInfo.phone}</a>
      </Section>
    </Bar>
  );
}

export default ContactBar;
