import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const SectionTitleLarge = styled.span`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 6rem;
  text-align: center;
  color: #a6a6a6;
  z-index: -1;
  position: relative;
  top: 25px;

  @media (min-width: 768px) {
    font-size: 10rem;
  }
`;

const SectionTitleSmall = styled.span`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 3rem;
  text-align: center;
  color: var(--neutral-dark);
  position: relative;

  @media (min-width: 768px) {
    font-size: 5rem;
    top: -20px;
  }
`;

const SectionTitle = ({ largeText, smallText }) => (
  <SectionTitleWrapper>
    <SectionTitleLarge>{largeText}</SectionTitleLarge>
    <SectionTitleSmall>{smallText}</SectionTitleSmall>
  </SectionTitleWrapper>
);

SectionTitle.propTypes = {
  largeText: PropTypes.string.isRequired,
  smallText: PropTypes.string.isRequired,
};

export default SectionTitle;
