const defaultTheme = {
  light: {
    primaryColor: '#658354', // Primary color
    secondaryColor: '#D87F33', // Complementary color
    tertiaryColor: '#8FA382', // Lighter shade of primary
    accentColor: '#4C634B', // Darker shade of primary
    black: '#403D39', // Dark neutral for text
    white: '#F3F4F6', // Light neutral for background
    neutralGray: '#A67C52', // Secondary accent color
    text: '#403D39', // Main text color
    background: '#F3F4F6', // Main background
    shadowColor: 'rgba(0, 0, 0, 0.1)', // Shadow color for light theme
  },
  dark: {
    primaryColor: '#658354', // Primary color
    secondaryColor: '#D87F33', // Complementary color
    tertiaryColor: '#8FA382', // Lighter shade of primary
    accentColor: '#4C634B', // Darker shade of primary
    black: '#F3F4F6', // Light neutral for text
    white: '#2E2E2E', // Dark neutral for background
    neutralGray: '#A67C52', // Secondary accent color
    text: '#F3F4F6', // Main text color
    background: '#2E2E2E', // Main background
    shadowColor: 'rgba(255, 255, 255, 0.1)', // Shadow color for dark theme
  },
};

export default defaultTheme;
