import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContextProvider from 'context/GlobalProvider';

import Home from 'components/Home';
import FullGallery from 'components/FullGallery';

const App = () => (
  <ContextProvider>
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/gallery' element={<FullGallery />} />
      </Routes>
    </Router>
  </ContextProvider>
);

export default App;
