import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { galleryImages } from '../config';
import ImageModal from './ImageModal';

const FullGalleryContainer = styled.div`
  max-width: ${({ maxGridWidth }) => maxGridWidth};
  margin: 40px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
`;

const GridItem = styled.button`
  overflow: hidden;
  position: relative;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
`;

const FullGallery = ({ maxGridWidth = '1200px' }) => {
  const [visibleImages, setVisibleImages] = useState([]);
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const imagesPerPage = 20;

  const loadImages = () => {
    const newImages = galleryImages.slice(0, page * imagesPerPage);
    setVisibleImages(newImages);
  };

  useEffect(() => {
    loadImages();
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const openModal = (index) => {
    setModalIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleKeyPress = (index, event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      openModal(index);
      event.preventDefault();
    }
  };

  return (
    <>
      <FullGalleryContainer maxGridWidth={maxGridWidth}>
        {visibleImages.map((image, index) => (
          <GridItem
            key={image.id}
            onClick={() => openModal(index)}
            onKeyDown={(e) => handleKeyPress(index, e)}
            aria-label={`View larger version of ${image.description}`}
          >
            <img src={image.url} alt={image.description} />
          </GridItem>
        ))}
      </FullGalleryContainer>

      {isModalOpen && (
        <ImageModal
          images={visibleImages}
          initialIndex={modalIndex}
          onClose={closeModal}
        />
      )}
    </>
  );
};

FullGallery.propTypes = {
  maxGridWidth: PropTypes.string.isRequired,
};

export default FullGallery;
