import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CardTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  height: 100%;
  justify-content: flex-start;

  @media (min-width: 768px) {
    padding: 35px 35px 0px 35px;
  }
`;

const EventTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const EventTitle = styled.h3`
  font-family: 'YoungerThanMe', sans-serif;
  color: #fff;
  font-size: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    font-size: 4em;
  }
`;

const SubTitle = styled.h4`
  font-family: 'PoppinsRegular', sans-serif;
  color: #fff;
  font-size: 1.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const EventDate = styled.div`
  font-size: 1.8rem;
  font-family: 'TekoMedium', sans-serif;
  color: #fff;
  padding: 5px 20px;
  background-color: rgba(40, 59, 27, 0.8);
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  letter-spacing: 1px;
  display: flex;
  width: 100%;

  @media (min-width: 768px) {
    width: 250px;
    font-size: 2.2rem;
  }
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  gap: 15px;
`;

const EventLocation = styled.p`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 2rem;

  @media (min-width: 768px) {
    font-size: 2.2rem;
  }
`;

const EventDescription = styled.p`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 1.3rem;
  line-height: 1.2;
  max-height: 100px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    font-size: 1.7rem;
    max-height: 150px;
    -webkit-line-clamp: 5;
  }
`;

const EventActions = styled.div`
  width: 100%;
  display: flex;
  margin-top: auto;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

const MoreInfoButton = styled.a`
  background-color: #fff;
  font-family: 'TekoMedium', sans-serif;
  padding: 15px 35px;
  color: var(--primary-color);
  font-size: 2rem;
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }

  @media (min-width: 600px) {
    font-size: 2rem;
    width: 170px;
    height: 50px;
    margin-top: 25px;
  }
`;

const CardTop = ({ event }) => (
  <CardTopWrapper>
    <EventTitleWrapper>
      <EventTitle>{event.title}</EventTitle>
      <SubTitle>{event.subtitle}</SubTitle>
    </EventTitleWrapper>
    <EventDate>{event.displayDate}</EventDate>
    <EventDetails>
      <EventLocation>{event.location}</EventLocation>
      <EventDescription>{event.description}</EventDescription>
    </EventDetails>
    <EventActions>
      <MoreInfoButton
        href={event.learnMore}
        target='_blank'
        rel='noopener noreferrer'
      >
        Learn More
      </MoreInfoButton>
    </EventActions>
  </CardTopWrapper>
);

CardTop.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    learnMore: PropTypes.string,
    date: PropTypes.string,
    endDate: PropTypes.string,
    displayDate: PropTypes.string,
    image1: PropTypes.string,
    image2: PropTypes.string,
  }).isRequired,
};

export default CardTop;
