import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FaTimes } from 'react-icons/fa';

const FullScreenMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    transform: ${(props) =>
      props.show ? 'translateX(0)' : 'translateX(-100%)'};
  }
`;

const MenuLink = styled.a`
  color: white;
  font-family: 'YoungerThanMe', sans-serif;
  letter-spacing: 2px;
  font-size: 2rem;
  padding: 20px;
  cursor: pointer;
  &:hover {
    color: #658354;
  }
`;

const CloseIcon = styled(FaTimes)`
  color: #ffffff;
  font-size: 3rem;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    color: #658354;
  }
`;
function NavMenu({ isMenuOpen, closeMenu, sections }) {
  return (
    <FullScreenMenu show={isMenuOpen}>
      <CloseIcon onClick={closeMenu} />
      {sections.map((section) => (
        <MenuLink key={section.id} onClick={closeMenu} href={section.link}>
          {section.name}
        </MenuLink>
      ))}
    </FullScreenMenu>
  );
}

// Define PropTypes
NavMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NavMenu;
