// Grid.jsx
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useModal } from '../context/Modal';

const GridContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: grid;
    gap: 10px;
    width: 95%;
    max-width: 900px;
    margin: 40px auto;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  }
`;

const GridItem = styled.button`
  overflow: hidden;
  position: relative;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  /* Set a consistent aspect ratio */
  aspect-ratio: 1 / 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;

const Grid = ({ images, columns, rows }) => {
  const { openModal } = useModal();

  // Calculate the maximum number of images to display based on rows and columns
  const maxImages = rows * columns;

  return (
    <GridContainer columns={columns}>
      {images.slice(0, maxImages).map((image, index) => (
        <GridItem
          key={image.id || index}
          onClick={() => openModal(index)}
          aria-label={`Open image ${index + 1}`}
        >
          <img
            src={image.url}
            alt={image.description || `Image ${index + 1}`}
          />
        </GridItem>
      ))}
    </GridContainer>
  );
};

const ImageShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
});

Grid.propTypes = {
  images: PropTypes.arrayOf(ImageShape).isRequired,
  columns: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
};

export default Grid;
