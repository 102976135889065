// Events
import React from 'react';
import styled from 'styled-components';
import SectionTitle from './SectionTitle';
import EventDisplay from './EventDisplay';
import { images } from '../../config';

const EventSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 80px auto;
  gap: 30px;
  padding: 70px;
  position: relative;
  background-image: url(${images.eventsBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

// const ViewCalendarButton = styled.button`
//   background-color: transparent;
//   font-family: 'TekoMedium', sans-serif;
//   padding: 5px;
//   color: #000;
//   font-size: 2rem;
//   cursor: pointer;
//   width: 100%;

//   @media (min-width: 768px) {
//     font-size: 2.5rem;
//     width: 500px;

//     &:hover {
//       color: var(--primary-color);
//     }
//   }
// `;

const Events = () => (
  <EventSection id='events'>
    <SectionTitle largeText='Calendar' smallText='Events Calendar' />
    <EventDisplay />
    {/* <ViewCalendarButton>View All Events</ViewCalendarButton> */}
  </EventSection>
);

export default Events;
