// SliderDots.jsx
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const DOT_SIZES = {
  small: '6px',
  medium: '8px',
  large: '12px',
};

const COLORS = {
  active: '#fff',
  inactive: 'rgba(255, 255, 255, 0.6)',
  hoverInactive: 'rgba(255, 255, 255, 0.8)',
};

const DotsContainer = styled.div`
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 2;

  @media (min-width: 768px) {
    bottom: 45px;
  }
`;

const DotButton = styled.button`
  width: ${(props) => DOT_SIZES[props.size]};
  height: ${(props) => DOT_SIZES[props.size]};
  margin: 0 4px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? COLORS.active : COLORS.inactive};
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  pointer-events: auto;
  vertical-align: middle;

  &:hover {
    background-color: ${(props) =>
      props.active ? COLORS.active : COLORS.hoverInactive};
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.9);
  }
`;

/**
 * A component that renders a horizontal row of dots,
 * with a "current" dot in the middle that is larger than the others.
 * The component is keyboard-navigable.
 *
 * @param {number} totalImages the total number of images in the slider
 * @param {number} currentIndex the index of the currently visible image
 * @param {function} onDotClick a function that is called when a dot is clicked
 * @return {ReactElement} a JSX element representing the row of dots
 */
const SliderDots = ({ totalImages, currentIndex, onDotClick }) => (
  <DotsContainer role='navigation' aria-label='Slider dots'>
    {Array.from({ length: totalImages }, (_, index) => {
      const isActive = index === currentIndex;
      const isAdjacent =
        index === currentIndex - 1 || index === currentIndex + 1;

      let size = 'small';
      if (isActive) {
        size = 'large';
      } else if (isAdjacent) {
        size = 'medium';
      }

      return (
        <DotButton
          key={`dot-${index}`}
          type='button'
          active={isActive}
          size={size}
          onClick={() => onDotClick(index)}
          aria-label={`Go to slide ${index + 1}`}
          aria-current={isActive ? 'true' : undefined}
        />
      );
    })}
  </DotsContainer>
);

SliderDots.propTypes = {
  totalImages: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  onDotClick: PropTypes.func.isRequired,
};

export default SliderDots;
