import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
  font-family: 'TekoMedium', sans-serif;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: ${(props) => props.bgColor || 'transparent'};
  color: #000;
  font-size: 2.5rem;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #969696;
    color: #fff;
  }

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const Button = ({ type, children, onClick, ariaLabel }) => (
  <StyledButton type={type} onClick={onClick} aria-label={ariaLabel}>
    {children}
  </StyledButton>
);

export default Button;

Button.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
};

Button.defaultProps = {
  onClick: () => {},
};
