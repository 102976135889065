import { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import validateAutocompleteValue from '../utils/autoCompleteValidation';

const Container = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  border-left: 3px solid #ccc;
  outline: none;
  padding: 5px 10px;
  font-family: inherit;
  letter-spacing: 0.5px;
  font-size: 2rem;
  transition:
    border-color 0.3s,
    border-left-width 0.3s;

  font-family: ${(props) => props.fontFamily || 'inherit'};
  color: ${(props) => props.textColor || 'inherit'};

  &:focus {
    border-left: 3px solid #658354;
    border-right: 1px solid #658354;
    border-top: 1px solid #658354;
    border-bottom: 1px solid #658354;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: -18px;
    left: 0;
    font-size: 1.8rem;
    color: #658354;
  }
`;

const PlaceholderLabel = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 2rem;
  color: #aaa;
  transition: all 0.3s;
  pointer-events: none;
`;

const Input = ({ id, placeholder, name, type, autocomplete }) => {
  const [label, setLabel] = useState(placeholder);

  return (
    <Container>
      <StyledInput
        id={id}
        placeholder={label ? '' : placeholder}
        name={name}
        type={type}
        onFocus={() => setLabel('')}
        onBlur={(e) => setLabel(e.target.value ? '' : placeholder)}
        autoComplete={autocomplete || 'off'}
      />
      <PlaceholderLabel htmlFor={id}>{placeholder}</PlaceholderLabel>
    </Container>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  autocomplete: validateAutocompleteValue,
};

Input.defaultProps = {
  autocomplete: 'off',
};

export default Input;
