import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useModal } from '../context/Modal';
import { galleryImages } from '../config';
import ImageSlider from './ImageSlider';
import SliderDots from './SliderDots';
import Grid from './Grid';
import ImageModal from './ImageModal';

const GalleryContainer = styled.div`
  position: relative;
  padding-top: 200px;
  min-height: 500px;
`;

const PhotoGalleryShadowTitle = styled.span`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 5rem;
  text-align: center;
  color: #eee;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  @media (min-width: 769px) {
    font-size: 9rem;
    top: 75px;
  }
`;

const PhotoGalleryTitle = styled.h2`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 4rem;
  color: var(--neutral-dark);
  line-height: 30px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  @media (min-width: 769px) {
    font-size: 5rem;
    top: 140px;
  }
`;

// const ShowMoreButton = styled(Link)`
//   display: block;
//   width: fit-content;
//   margin: 20px auto;
//   padding: 10px 20px;
//   color: var(--neutral-dark);
//   text-decoration: none;
//   font-family: 'PoppinsRegular', sans-serif;
//   font-size: 1.8rem;
// `;

const ImageSliderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

const useMediaQuery = (query) => {
  const [matches, setMatches] = React.useState(
    window.matchMedia(query).matches
  );

  React.useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const handler = (event) => setMatches(event.matches);
    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, [query]);

  return matches;
};

const Gallery = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { isOpen, closeModal, currentIndex, setCurrentIndex } = useModal();

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + galleryImages.length) % galleryImages.length
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <GalleryContainer>
      <PhotoGalleryShadowTitle>Gallery</PhotoGalleryShadowTitle>
      <PhotoGalleryTitle>Gallery</PhotoGalleryTitle>

      {isMobile ? (
        <ImageSliderWrapper>
          <ImageSlider
            images={galleryImages}
            currentIndex={currentIndex}
            onPrev={handlePrev}
            onNext={handleNext}
          />
          <SliderDots
            totalImages={galleryImages.length}
            currentIndex={currentIndex}
            onDotClick={handleDotClick}
          />
        </ImageSliderWrapper>
      ) : (
        <Grid images={galleryImages} columns={3} rows={2} />
      )}
      {/* <ShowMoreButton to='/gallery'>Show More</ShowMoreButton> */}

      {isOpen && (
        <ImageModal
          images={galleryImages}
          initialIndex={currentIndex}
          onClose={closeModal}
        />
      )}
    </GalleryContainer>
  );
};

export default Gallery;
