import React from 'react';
import styled from 'styled-components';
import { sections, images } from '../config';

const NavList = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(5, 90px);
  gap: 50px;
  justify-content: center;
`;

const NavItem = styled.li`
  padding: 10px;
  font-family: 'TekoMedium', sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageDiv = styled.div`
  position: absolute;
  top: -20px;
  max-width: 100%;
  max-height: 100px;
  display: none;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  ${NavItem}:hover & {
    display: block;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 3rem;
  text-align: center;
  width: 100%;
`;

const Navbar = () => (
  <nav>
    <NavList>
      {sections.map((section) => (
        <NavItem key={section.id}>
          <ImageDiv>
            <img src={images.navHoverIcon} alt='Nav Hover Icon' />
          </ImageDiv>
          <NavLink href={section.link}>{section.name}</NavLink>
        </NavItem>
      ))}
    </NavList>
  </nav>
);

export default Navbar;
