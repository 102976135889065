import styled from 'styled-components';
import Navbar from './Navbar';
import { images, siteInfo } from '../config';

const FooterContainer = styled.footer`
  background-color: #ffffff;
  background-image: url(${images.footerBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 700px;
  padding-bottom: 20px;
  position: relative;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 10px;
    background-size: cover;
  }
`;
const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

const Logo = styled.img`
  width: 200px;
`;

const FooterMessage = styled.p`
  font-family: 'PoppinsRegular';
  line-height: 25px;
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  max-width: 600px;
  padding: 0 20px;
  @media (max-width: 768px) {
  }
`;

const BoldText = styled.span`
  font-family: 'PoppinsBold';
  font-size: 1.1rem;
`;

const Navigation = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Footer = () => (
  <FooterContainer>
    <FooterWrapper>
      <Logo src={images.footerLogo} alt='Whiskey Throttle Cafe' />
      <FooterMessage>{siteInfo.bio1}</FooterMessage>
      <Navigation>
        <Navbar />
      </Navigation>
      <p>
        &copy; 2023 <BoldText>WHISKEY THROTTLE CAFE LLC.</BoldText> ALL RIGHTS
        RESERVED.
      </p>
    </FooterWrapper>
  </FooterContainer>
);

export default Footer;
