import styled from 'styled-components';
import { images, siteInfo } from '../config';

const AboutUs = styled.section`
  height: auto;
  background-image: url(${images.topoBackground});
  background-size: cover;
  background-position: center;
  background-blend-mode: soft-light;
  position: relative;
  z-index: 2;
  top: -100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  padding: 120px 0px;

  @media (max-width: 768px) {
    top: -50px;

    padding: 120px 0px;
  }
`;

const SectionShadowTitle = styled.span`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 10rem;
  text-align: center;
  color: #eee;
  position: absolute;
  top: 75px;
  left: 150px;
  z-index: -1;
  @media (max-width: 768px) {
    transform: rotate(-90deg);
    top: 395px;
    left: -130px;
  }
`;

const Title = styled.h2`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 2.5rem;
  color: #fff;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 2rem;
    letter-spacing: 1px;
  }
`;

const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const AboutUsPicture = styled.div`
  width: 350px;
  height: 350px;
  background-image: url(${images.aboutImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

const AboutUsContent = styled.div`
  padding: 30px;
  width: 450px;
  height: 450px;

  background-color: #59754a;
  background-image: url(${images.aboutBackgroundLogo});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 50%;

  @media (max-width: 768px) {
    width: 300px;
    height: 400px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: 80%;
    background-position: center;
  }
`;

const Message = styled.p`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 1.8rem;
  color: #fff;
  margin: 40px 0;
  line-height: 3rem;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    margin: 20px 0;
    line-height: 2rem;
  }
`;

const About = () => (
  <AboutUs id='about'>
    <SectionShadowTitle>about</SectionShadowTitle>
    <AboutUsWrapper>
      <AboutUsPicture />
      <AboutUsContent>
        <Title>Welcome to</Title>
        <Title>Whiskey Throttle Cafe</Title>
        <Message>{siteInfo.about1}</Message>
      </AboutUsContent>
    </AboutUsWrapper>
  </AboutUs>
);

export default About;
