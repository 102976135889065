import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import EventCard from './EventCard';
import { images } from '../../config';

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
`;

const ImageWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

const LeftImage = styled.img`
  width: 300px;
  height: 400px;
  object-fit: cover;
  transform: perspective(800px) rotate(-10deg) rotateX(20deg); /* Rotates left and tilts back */
  transform-origin: center; /* Ensures the transformation happens from the center */
  position: relative;
  left: 45px;
  z-index: -1; /* Adjusted for visibility */
  bottom: 20px;
`;

const RightImage = styled.img`
  width: 300px;
  height: 400px;
  object-fit: cover;
  transform: perspective(800px) rotate(10deg) rotateX(15deg); /* Rotates right and tilts back */
  transform-origin: center; /* Ensures the transformation happens from the center */
  position: relative;
  right: 45px;
  top: 20px;
  z-index: -1; /* Adjusted for visibility */
`;
const CurrentEventButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  height: 50px;
`;

const CurrentEventButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 50px;
  font-size: 1.8rem;
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  gap: 10px;

  &:disabled {
    color: grey;
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    font-size: 2rem;
    margin-top: 20px;
  }
`;

const events = [
  {
    id: 1,
    title: 'Dick Jagow',
    subtitle: 'West Hare Scramble GP',
    description:
      "Join us for breakfast and lunch! We'll be serving biscuits and gravy, breakfast sandwiches and coffee, followed by gourmet sausages and hotdogs for lunch in the afternoon. All fresh ingredients and made to order.",
    location: 'Washougal MX Park',
    date: '2024-09-28',
    endDate: '2024-09-29',
    displayDate: 'Sept. 28th-29th',
    image1: images.washougal1,
    image2: images.washougal2,
    learnMore:
      'https://www.omraoffroad.com/events/calendar/2024/4/28/xc-series-round-6-dick-jagow-hs',
  },
  {
    id: 2,
    title: 'Pro Caliber',
    subtitle: 'Open House - Sled Swap',
    description:
      "Join us for breakfast and lunch! We'll be serving biscuits and gravy, breakfast sandwiches and coffee, followed by gourmet sausages and hotdogs for lunch in the afternoon. All fresh ingredients and made to order.",
    location: 'Pro Caliber Bend',
    date: '2024-10-26',
    endDate: '2024-10-26',
    displayDate: 'Oct. 26th 9am - 5pm',
    image1: images.procaliber1,
    image2: images.procaliber2,
    learnMore:
      'https://www.instagram.com/p/DAUWGpOyYFd/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
  },
];

const EventDisplay = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextUpcomingIndex, setNextUpcomingIndex] = useState(0);
  const [sortedEvents, setSortedEvents] = useState([]);

  // Sort events and determine the current event
  useEffect(() => {
    const sorted = [...events].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    setSortedEvents(sorted);

    const today = new Date();
    const upcomingIndex = sorted.findIndex(
      (event) => new Date(event.date) >= today
    );

    if (upcomingIndex !== -1) {
      // There is an upcoming event
      setNextUpcomingIndex(upcomingIndex);
      setCurrentIndex(upcomingIndex);
    } else {
      // All events are in the past
      // Find the most recent past event
      const recentPastIndex = sorted
        .map((event) => new Date(event.date) < today)
        .lastIndexOf(true);

      setNextUpcomingIndex(recentPastIndex);
      setCurrentIndex(recentPastIndex);
    }
  }, []);

  const handleNext = () => {
    if (currentIndex < sortedEvents.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleCurrentEvent = () => {
    setCurrentIndex(nextUpcomingIndex);
  };

  const currentEvent = sortedEvents[currentIndex];
  const totalEvents = sortedEvents.length;

  if (!currentEvent) {
    return null; // Or display a placeholder/loading indicator
  }

  const readableDate = moment(currentEvent.date).format('MMMM D, YYYY');
  const computerReadableDate = moment(currentEvent.date).format('YYYY-MM-DD');

  return (
    <EventsContainer>
      <SliderWrapper>
        <ImageWrapper>
          <LeftImage
            src={currentEvent.image1}
            alt={`${currentEvent.title} Image 1`}
          />
        </ImageWrapper>
        <EventCard
          event={currentEvent}
          readableDate={readableDate}
          computerReadableDate={computerReadableDate}
          displayDate={currentEvent.displayDate}
          handleNext={handleNext}
          handlePrev={handlePrev}
          isNextDisabled={currentIndex === totalEvents - 1}
          isPrevDisabled={currentIndex === 0}
          totalEvents={totalEvents}
        />
        <ImageWrapper>
          <RightImage
            src={currentEvent.image2}
            alt={`${currentEvent.title} Image 2`}
          />
        </ImageWrapper>
      </SliderWrapper>
      <CurrentEventButtonWrapper>
        {nextUpcomingIndex !== currentIndex && (
          <CurrentEventButton onClick={handleCurrentEvent}>
            {nextUpcomingIndex !== -1
              ? 'Go To Current Event'
              : 'Go To Most Recent Event'}
          </CurrentEventButton>
        )}
      </CurrentEventButtonWrapper>
    </EventsContainer>
  );
};

export default EventDisplay;
