import styled from 'styled-components';
import { images } from '../config';

const MenuWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
`;
const SectionShadowTitle = styled.span`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 10rem;
  text-align: center;
  color: #eee;
  z-index: -1;
  position: absolute;
  top: 0px;
  @media (max-width: 768px) {
    font-size: 8rem;
    top: 20px;
  }
  @media (max-width: 425px) {
    font-size: 6rem;
  }
`;

const Title = styled.h1`
  font-family: 'YoungerThanMe', sans-serif;
  font-size: 5rem;
  text-align: center;
  color: var(--neutral-dark);
  margin-top: 50px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  margin: 20px;
  border: 1px solid #59754a;
  background-color: #fff;
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const CardTitle = styled.h3`
  font-family: 'TekoMedium', sans-serif;
  font-size: 3rem;
  line-height: 40px;
  color: #59754a;
`;

const menuData = [
  {
    imageSrc: images.sandwich,
    title: 'Breakfast Sandwiches',
  },
  {
    imageSrc: images.hotdog,
    title: 'Hot Dogs',
  },
  {
    imageSrc: images.sausage,
    title: 'Sausages',
  },
  {
    imageSrc: images.coffee,
    title: 'Coffee',
  },
  {
    imageSrc: images.snacks,
    title: 'Snacks and Drinks',
  },
];

const Menu = () => (
  <MenuWrapper id='menu'>
    <SectionShadowTitle>Our Menu</SectionShadowTitle>
    <Title>Our Menu</Title>
    <MenuContainer>
      {menuData.map((item) => (
        <Card key={item.title}>
          <CardImage src={item.imageSrc} alt='Card Image' loading='lazy' />
          <CardDescription>
            <CardTitle>{item.title}</CardTitle>
          </CardDescription>
        </Card>
      ))}
    </MenuContainer>
  </MenuWrapper>
);

export default Menu;
