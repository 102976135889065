"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultTheme = void 0;
var defaultTheme = exports.defaultTheme = {
  light: {
    primaryColor: '#013F6A',
    secondaryColor: '#2EBFD4',
    tertiaryColor: '#93CCEA',
    accentColor: '#FF910F',
    black: '#090909',
    white: '#ffffff',
    neutralGray: '#ACACAC',
    text: '#090909',
    background: '#ffffff',
    shadowColor: 'rgba(0, 0, 0, 0.1)'
  },
  dark: {
    primaryColor: '#2EBFD4',
    secondaryColor: '#013F6A',
    tertiaryColor: '#1A1A1A',
    accentColor: '#FF910F',
    black: '#ffffff',
    white: '#090909',
    neutralGray: '#757575',
    text: '#ffffff',
    background: '#090909',
    shadowColor: 'rgba(255, 255, 255, 0.1)'
  }
};