import styled from '@emotion/styled';
import Input from './Input';
import Textarea from './Textarea';
import Button from './Button';

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  width: 80%;
  max-width: 800px;

  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const InputContainer = styled.div`
  font-family: 'TekoMedium', sans-serif;

  display: flex;

  justify-content: space-between;
  grid-gap: 25px;
  flex-direction: column;
`;

const TextAreaContainer = styled.div`
  font-family: 'TekoMedium', sans-serif;
`;

const FullSpanButton = styled.div`
  grid-column: 1 / -1;
`;

const ContactForm = () => (
  <Form
    aria-labelledby='contact-form'
    name='contact-form'
    method='post'
    data-netlify='true'
  >
    <input type='hidden' name='form-name' value='contact-form' />
    <InputContainer>
      <Input
        id='name'
        type='text'
        name='name'
        placeholder='Name'
        autocomplete='name'
      />
      <Input
        id='email'
        type='email'
        name='email'
        placeholder='Email'
        autocomplete='email'
      />
      <Input
        id='phone'
        type='phone'
        name='phone'
        placeholder='Phone'
        autocomplete='tel'
      />
    </InputContainer>
    <TextAreaContainer>
      <Textarea id='message' type='text' name='message' placeholder='Message' />
    </TextAreaContainer>
    <FullSpanButton type='submit'>
      <Button type='submit' ariaLabel='Submit'>
        Submit
      </Button>
    </FullSpanButton>
  </Form>
);

export default ContactForm;
