// ContextProvider.js
import React from 'react';
import PropTypes from 'prop-types';
import { ModalProvider } from './Modal';

const ContextProvider = ({ children }) => (
  <ModalProvider>{children}</ModalProvider>
);

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProvider;
